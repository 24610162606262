import React, {useEffect, useState} from 'react'
import './style.scss'
import {dsnCN} from "../../hooks/helper";
import InfluencerItem from "./InfluencerItem";
import {DATA_SOURCE, INFLUENCERS_TYPE} from "../../config";
import {getInfluencersByType} from "../../api/influencers/InfluencersProvider";
import {useData} from "../../api/fetchData";
import Masonry from 'react-masonry-css';

const InfluencersPortfolio = ({
                                  className,
                                  influencers,
                                  influencersFollowers,
                                  influencersType,
                                  limit = null,
                                  col = 3,
                                  colTablet = 2,
                                  colMobile = 1,
                              }) => {
    const [influencersData, setInfluencersData] = useState([]);
    const allBadges = useData(DATA_SOURCE.BADGES);

/*    useEffect(() => {
      //  iso.current = new Isotope(refIsotope.current, {itemSelector: '.portfolio-item'});

        return () => {
           // iso.current.destroy();
        }
    });*/

/*    useEffect(() => {
      //  iso.current.arrange({filter: filter});
    }, [filter])*/

    useEffect(() => {
        let fetchedInfluencers = getInfluencersByType(influencers, influencersType);
        if (limit) {
            fetchedInfluencers = fetchedInfluencers.slice(0, limit);
        }

        if (fetchedInfluencers && influencersFollowers) {
            const processedFollowers = getInfluencersByType(influencersFollowers, influencersType);
            const mergedData = fetchedInfluencers.map(influencer => {
                const correspondingFollowerData = processedFollowers.find(follower => follower.id === influencer.id);
                if (correspondingFollowerData) {
                    Object.keys(influencer.social).forEach(platform => {
                        if (correspondingFollowerData.social[platform] && correspondingFollowerData.social[platform].followers.length > 0) {
                            influencer.social[platform].followers = correspondingFollowerData.social[platform].followers;
                        }
                    });
                }
                return influencer;
            });

            setInfluencersData(mergedData);
        }
        else if(fetchedInfluencers)
        {
            setInfluencersData(fetchedInfluencers);
        }
    }, [influencers, influencersFollowers, influencersType, limit]);

    const breakpointColumnsObj = {
        default: col,
        1100: col,
        991: colTablet,
        575: colMobile,
        450: 1
    };


    const isPrimary = influencersType === INFLUENCERS_TYPE.PRIMARY;

    return (
        <section className={dsnCN('dsn-portfolio port-classic', !isPrimary ? 'data-influencer' : '', className)}>
{/*            <DsnGrid {...restProps} ref={refIsotope} col={!isPrimary ? 4 : 3} colTablet={!isPrimary ? 3 : 2}
                     colMobile={1}>*/}

                <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column">
                {influencersData && influencersData.map((item, index) =>
                    <InfluencerItem
                        key={item?.id || index}
                        influencer={item}
                        allBadges={allBadges}/>
                )}

                </Masonry>

    {/*        </DsnGrid>*/}
        </section>
    );
}

export default InfluencersPortfolio
