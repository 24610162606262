import {
    faFacebookSquare,
    faInstagram,
    faLinkedinIn,
    faTiktok,
    faTwitch,
    faXTwitter,
    faYoutube
} from "@fortawesome/free-brands-svg-icons";

export const socialIcons = {
    facebook: faFacebookSquare,
    instagram: faInstagram,
    tiktok: faTiktok,
    youtube: faYoutube,
    twitch: faTwitch,
    twitter: faXTwitter,
    x: faXTwitter,
    linkedin: faLinkedinIn
};
