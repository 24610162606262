import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux'
import { store } from './app/store'
import {createRoot} from "react-dom/client";

const MainApp = () => {
  return (
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
  );
};

const root = createRoot(document.getElementById("root"));
root.render(
        <MainApp/>
);

reportWebVitals();
