import React, {useEffect, useRef } from 'react'
import MetaPost from "../header/MetaPost";
import NavLink from "../button/NavLink";
import BgImage from "../header/BgImage";
import {getPortfolioLink} from "../../api/portfolio/PortfolioData";
import {dsnCN} from "../../hooks/helper";
import {getResourceUrl} from "../../urlUtils";
import {isMobile} from 'react-device-detect';


function PortfolioItem({portoDetails, textButton, imageOnly, className}) {

    const ref = useRef();
    const pauseTimeRef = useRef(0.0); // Użyj useRef zamiast useState

    const link = getPortfolioLink(portoDetails);

    useEffect(() => {

        const video = ref.current.querySelector('video');
        if (!video)
            return;

       if(isMobile)
        {
            video.play();
            return;
        }
        video.pause();
        const portItem = ref.current,
            mouseEnter = () => {
                video.currentTime = pauseTimeRef.current;
                video.play().catch(e => {
                    // ignore
                });
            },
            mouseLeave = () => {
                pauseTimeRef.current = video.currentTime;
                video.load();
                video.pause();
            };

        portItem.addEventListener('mouseenter', mouseEnter);
        portItem.addEventListener('mouseleave', mouseLeave);

        return () => {
            if (!video)
                return;
            portItem.removeEventListener('mouseenter', mouseEnter);
            portItem.removeEventListener('mouseleave', mouseLeave);
        }

    }, []);

    return (
        <div key={portoDetails.id} className={dsnCN('portfolio-item text-center v-dark-head', className)} ref={ref}>
            <NavLink className="btn-portfolio" to={link}>
            <div className='inner-img'>
                <BgImage height="100%" className={"dsn-swiper-parallax-transform"} src={getResourceUrl(portoDetails.src)} video={getResourceUrl(portoDetails.video)}
                         alt={portoDetails.title}
                         overlay={2} imageOnly={imageOnly}/>
            </div>
            <div className="info-text">
                {portoDetails.category && <MetaPost category={portoDetails.category} separate=", "/>}

                {portoDetails.title &&
                <h4 className="title-block">{portoDetails.company ? portoDetails.company : portoDetails.title}
                </h4>}

                <div className="btn-portfolio">{textButton}    </div>
            </div>
            </NavLink>
        </div>

    )
}


export default React.memo(PortfolioItem);
