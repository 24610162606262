import React, {useEffect} from "react";
import Navbar, {Nav} from "../../nav/Navbar";
import Brand from "../../brand/Brand";
import MenuContent from "./MenuContent";
import Scrollbar from "smooth-scrollbar";
import {useSelector} from "react-redux";
import useHandleClick from "../../../hooks/SmoothNavigation";

export const menuContent = [
    {name: "O nas", to: "about"},
    {name: "Współprace", to: "clients"},
    {name: "Kampanie", to: "casestudies"},
    {name: "Influencerzy", to: "influencers"},
    {name: "Usługi", to: "services"},
    {name: "Kontakt", to: "contact"},
];

const DroowMenu = ({hamburger}) => {

    const scrollbar: React.MutableRefObject<null | Scrollbar> = useSelector(state => state.scrollbar);

    const handleClick = useHandleClick();

    useEffect(() => {
        const scrollToHash = () => {
            const hash = window.location.hash.substring(1);
            if (!hash || !scrollbar) return;


            if (hash) {
                setTimeout(() => {
                    const targetElement = document.getElementById(hash);
                    if (targetElement) {
                        scrollbar?.current?.scrollIntoView(targetElement);
                    }
                }, 1000);
            }
        };

        if (!scrollbar || !window.location.hash) return;

        let attemptCount = 0;

        if (scrollbar) {
            const checkInterval = setInterval(() => {
                const hash = window.location.hash.substring(1);
                const targetElement = document.getElementById(hash);

                attemptCount++;

                if (targetElement || attemptCount > 20) {
                    clearInterval(checkInterval);
                    if (targetElement) {
                        scrollToHash();
                    }
                }
            }, 100);
            return () => clearInterval(checkInterval);
        }
    }, [scrollbar]);


    let $key = 0;
    const getSubMenu = (items) => {
        $key++;
        if (items.dropdownMenu) {
            return (<Nav.Dropdown name={items.name}
                                  key={$key}>{items.dropdownMenu.map(item => getSubMenu(item))}</Nav.Dropdown>);
        }
        return (
            <Nav.Link to={"/#" + items.to} onClick={() => handleClick(items.to)} key={$key}>{items.name}</Nav.Link>
        );
    }

    return (
        <Navbar hamburger={hamburger}>
            <Navbar.Brand to={"/"}>
                <Brand width="80px" height="26px"/>
            </Navbar.Brand>

            <Navbar.Collapse cover="Menu">
                <Nav>
                    {menuContent.map(item => getSubMenu(item))}
                </Nav>
                <MenuContent className="section-margin"/>
            </Navbar.Collapse>
        </Navbar>
    );
}

DroowMenu.defaultProps = {
    hamburger: false
}

export default DroowMenu;
