import React from 'react';
import NavLink from "../button/NavLink";
import {dsnCN} from "../../hooks/helper";
import {menuContent} from "../header/menu/DroowMenu";
import useHandleClick from "../../hooks/SmoothNavigation";


function MenuFooter({className, ...restProps}) {

    const handleClick = useHandleClick();

    return (
        <ul className={dsnCN('col-menu', className)} {...restProps}>
            {
                menuContent.map(
                    (item, i) =>
                        <li key={i}><NavLink  to={"/#" + item.to} onClick={() => handleClick(item.to)}>{item.name}</NavLink></li>
                )
            }
        </ul>
    );
}

export default MenuFooter;
