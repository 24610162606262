import React from 'react';
import SocialMedia from "../../social-media/SocialMedia";

function MenuContent() {

    return (
      <div className="nav-social">
        <SocialMedia/>
      </div>
    );
}

export default MenuContent;
