
export const getPortfolioItem = (value, whereName = "id", data) => {
        const foundItem = data?.find(item => Number(item[whereName]) === Number(value));
        if (foundItem) {
            return foundItem;
        }
    return null;
};

export const getPortfolioLink = (item) => {
    if (item)
        return item.id && '/casestudy/' + item.id;

    return '';
};
