import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {LoadingSpinner} from "./Spinner";


export interface LazyImgProps extends React.ImgHTMLAttributes {
    targetRef?: React.LegacyRef,
    readAsBlob?: boolean,
}

function LazyImg({src: lp, readAsBlob, targetRef, ...restProps}: LazyImgProps) {

    const [url, setUrl] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetch(lp)
            .then(response => readAsBlob ? response.blob() : response.url)
            .then(blob => {

                if (!readAsBlob) {
                    setUrl(blob);
                } else {
                    const fr = new FileReader();
                    fr.readAsDataURL(blob);
                    fr.onload = function () {
                        setUrl(this.result);
                    };
                }
                setIsLoading(false);
            })
    }, [lp, readAsBlob]);

    return (
        <React.Fragment>
            {isLoading && <Spinner  style={{height: "100%"}}><LoadingSpinner  /></Spinner> }
            <img
                src={url}
                alt={restProps.alt || 'Head Start'}
                style={{ opacity: isLoading ? 0 : 1 }}
                width="100%"
                height="100%"
                {...restProps}
                ref={targetRef}

            />
        </React.Fragment>
    );
}

const Spinner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

LazyImg.defaultProps = {
    readAsBlob: true
}

export default LazyImg;
