import React from 'react';
import {Helmet} from "react-helmet";
import {Container} from "react-bootstrap";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";

function NotFound(props) {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Helmet>
        <title>Head Start Agency</title>
      </Helmet>
      <Header>
          <Container className="hero-content">

              <div className="text-head">
                <h1 className="title-heading">404</h1>
              </div>
            <div className="text-sub">
              <span>Strona o podanym adresie nie istnieje</span>
            </div>
          <button onClick={() => navigate("/")}>Wróć do strony głównej</button>
          </Container>
      </Header>

    </React.Fragment>
  )
}

export default NotFound;

const Header = styled.header`

  height: 100vh;


  .hero-content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    text-align: center;
  }

  .title-heading {
    font-size: 100px;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: .5px;
    font-weight: 700;
  }

  .text-sub {
    font-size: 30px;
    margin: 30px;
  }

  .text-head {
    position: relative;

    h1 {
      color: var(--heading-color);
      -webkit-text-stroke: 1px var(--heading-color);
    }

    .h1 {
      clip-path: inset(70% 0 -10px 0);
    }
  }
`;
