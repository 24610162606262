import React from 'react';

import MainScrollBar from "./MainScrollBar";
import type {SmoothScrollbarProps} from "./SmoothScrollbar";
import DroowMenu from "../components/header/menu/DroowMenu";
import Footer from "../components/footer/Footer";


interface LayoutProps extends SmoothScrollbarProps {
    tag?: React.ElementType,
    activeScrollbar?: boolean
}

function Layout(
    {className, children, tag: Tag = "div", activeScrollbar, ...restProps}: LayoutProps) {

    return (
        <Tag id="main_layout" className={className}>
            <DroowMenu hamburger={false}/>
{/*
            <LoadingPage/>
*/}
            {activeScrollbar ?
              <MainScrollBar  {...restProps}>
                {children}
                  <Footer/>
              </MainScrollBar> :
                <>
                    {children}
                    <Footer/>
                </>
            }
        </Tag>
    );
}

export default Layout;
