import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import {Container} from "react-bootstrap";
import DsnGrid from "../../components/DsnGrid";
import VideoWrapper from "../../components/case-study/VideoWrapper";
import CaseStudyInfo from "../../components/case-study/CaseStudyInfo";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import {useData} from "../../api/fetchData";
import {DATA_SOURCE} from "../../config";
import {faCircleXmark} from "@fortawesome/free-regular-svg-icons";
import {LoadingSpinner} from "../../components/Spinner";
import {getResourceUrl} from "../../urlUtils";


function CaseStudy() {
    const navigate = useNavigate();
    const data = useData(DATA_SOURCE.CASE_STUDIES);
    const {itemId} = useParams();
    const [item, setItem] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
            if (data && data.length > 0) {
                const foundItem = data?.find(item => Number(item["id"]) === Number(itemId));
                setItem(foundItem);
                setIsLoading(false)
            }
        }
    , [itemId, data]);


    return (
        <React.Fragment>
            <Helmet>
                <title>{item?.title ? item.title : "Case Study"} - Head Start Agency</title>
            </Helmet>

            <div className="p-3"></div>

             <Container className="section-margin" id="casestudy">
                <div className="mb-5">

                    <button className="lh-base" onClick={() => navigate("/casestudies")}>
                        <FontAwesomeIcon className="me-3" icon={faChevronLeft}/> Powrót do wszystkich kampanii
                    </button>
                </div>


                 {isLoading ? (
                     <div className="text-center p-100">
                         <LoadingSpinner/>
                     </div>
                 ) : item ? ( <section>
                    <DsnGrid col={2} colTablet={1} colMobile={1}>

                        <VideoWrapper
                            src={getResourceUrl(item.src)}
                            video={getResourceUrl(item.video)}
                            alt={item.title}
                            overlay={2}
                            height={"100%"}/>

                        <CaseStudyInfo item={item} className="background-section"/>

                    </DsnGrid>
                </section>) :  (
                    <div className="text-center p-5">
                        <h1> <FontAwesomeIcon icon={faCircleXmark}/> </h1>
                        <div>
                            <h4>
                   Nie znaleziono kampanii
                            </h4>
                        </div>
                    </div>
                 ) }

            </Container>

        </React.Fragment>
    );
}

export default CaseStudy;
