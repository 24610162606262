import React from "react";
import styled from "styled-components";
import LazyImg from "../LazyImg";

const Bg = styled.div`
    height: ${(props) => props.theme.height};
`;

const BgImage = React.forwardRef(({src, video, height, overlay, imageOnly, alt, ...restProps}, ref) => {
    let loop = true;
    if(video){
        if(typeof video == "object")
        {
            if(video.poster){
                loop = video.loop
                let imgSrcTemp = video.poster;
                video = src;
                src = imgSrcTemp;

            }else {
                return null;
            }
        }
    }
    return (
      <Bg theme={{height: height}} data-overlay={overlay} className="hero-img overflow" {...restProps} ref={ref}>
          {!video && <LazyImg src={src} className="cover-bg-img" alt={alt}/>}
          {(video && !imageOnly) &&
            <video className="cover-bg-video" preload="metadata" autoPlay muted playsInline loop={loop} poster={src} >
                <source src={video} type="video/mp4"/>
            </video>}
          {(video && imageOnly) && <LazyImg className="cover-bg-video" src={src} alt={alt}/>}
      </Bg>

    );
});

BgImage.defaultProps = {
    height: "80vh",
    overlay: 4,
}

export default BgImage;
