import React from 'react'
import './style.scss'

import {Container} from 'react-bootstrap';

import SocialMedia from '../social-media/SocialMedia';
import Brand from "../brand/Brand";
import NavLink from "../button/NavLink";
import MenuFooter from "./MenuFooter";
import Copyright from "./Copyright";
import {dsnCN} from "../../hooks/helper";
import Scrollbar from "smooth-scrollbar";
import {useSelector} from "react-redux";


function Footer({className, ...restProps}) {

    const scrollbar: React.MutableRefObject<null | Scrollbar> = useSelector(state => state.scrollbar);

    const handleScrollTop = () => {
        const element = document.getElementById("home");
        if (window.location.pathname !== '/') {
                window.location.href = '/';
            } else if (scrollbar) {
            scrollbar.current.scrollIntoView(element);
            }
    };

    return (
        <footer className={dsnCN('footer background-section', className)} {...restProps}>
            <Container>
                <div className="footer-links pt-40 pb-20">
                    <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                        {/*Band & Social*/}
                        <div className='dsn-col-footer'>
                            <NavLink to="/" className='d-flex flex-column justify-content-center align-items-center flex-sm-row' onClick={() => handleScrollTop()}>
                                <Brand width="100px" height="100px"/>
                                <div className="brand-name">
                                    <div>Head</div>
                                    <div>Start</div>
                                    <div>Agency</div>
                                </div>
                            </NavLink>
                        </div>
                        <div className='dsn-col-footer  mt-4 mt-sm-0'>
                        <SocialMedia className="mt-20 text-center"/>
                        </div>
                        {/*Navigation*/}
                        <div className='dsn-col-footer mt-5 mt-sm-0 menu-footer'>
                            <h4 className="footer-title">Nawigacja</h4>
                            <MenuFooter/>
                        </div>

        {/*                Contact
                        <div className='dsn-col-footer'>
                            <h4 className="footer-title">Contact</h4>
                            <ContentFooter/>
                        </div>


                        <div className='dsn-col-footer'>
                            <h4 className="footer-title">Address</h4>
                            <AddressFooter/>
                        </div>*/}
                    </div>
                </div>
                <Copyright className="pt-40 pb-40"/>
            </Container>
        </footer>
    )
}


export default Footer
