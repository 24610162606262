import React, {useEffect, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style.scss'
import {dsnCN} from "../../hooks/helper";
import ParallaxImage from "../parallax-image/ParallaxImage";
import Badge from "./badge/Badge";
import {socialIcons} from "../../layout/SocialIcons";
import {getResourceUrl} from "../../urlUtils";

function InfluencerItem({influencer, allBadges, className}) {

  const [badges, setBadges] = useState([]);

    useEffect(() => {
        if (influencer?.badges && Array.isArray(allBadges)) {
            const influencerBadges = influencer.badges.map(influencerBadge => {
                const baseBadge = allBadges.find(badge => badge.type === influencerBadge.type);
                return {
                    ...baseBadge,
                    ...influencerBadge
                };
            }).slice(0, 3);

            setBadges(influencerBadges);
        }
    }, [influencer, allBadges]);

  if(!influencer) return null;

  if (!influencer.title && !influencer.name) return null;

  if (influencer.title === influencer.name) influencer.name = null;
  if (influencer.name && !influencer.title) {
    influencer.title = influencer.name;
    influencer.name = null;
  }

  const formatFollowers = (num) => {
    return String(num).replace(',', '.');
  }

  return (
    <div className={dsnCN('influencer-item portfolio-item text-center v-dark-head', className)}>

      <div className="blog-item">
        <div className='inner-img'>
          <div className="box-img">
            <ParallaxImage src={getResourceUrl(influencer?.src)} alt="" speed={15} />
          </div>
        </div>
        <div className="box-content background-section">
          {badges && badges.map((badge, index) => (
              <div className="badge-container" key={influencer?.id + "-" +  index} style={{ '--badge-index': index }} >
                <Badge badgeItem={badge}/>
              </div>
          ))}
          {influencer?.name && <h6 className="full-name">{influencer?.name}</h6>}
          {influencer?.title && <h5 className="title-name">{influencer?.title}</h5>}
          {influencer?.social && (
            <div className="social-info">
              {Object.entries(influencer?.social).map(([key, platform]) => (
                platform && platform.url && (
                  <a type="button"  className="button" key={key} href={platform.url} target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={socialIcons[key]}/> {formatFollowers(platform.followers)}
                  </a>
                )
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default InfluencerItem
