import './style.scss'
import {getResourceUrl} from "../../../urlUtils";

function Badge({badgeItem}) {


  if(!badgeItem) return null;
  return (
    <div className="center">
      <div className="circle">
        <div className="logo">
          <img src={getResourceUrl(badgeItem.img)} alt={badgeItem.name} className="badge-image" width="80px" height="80px"/>
        </div>
        <div className="text">
          <svg x="0" y="0" viewBox="0 0 300 300" enableBackground="new 0 0 300 300" xmlSpace="preserve">
            <defs>
              <path id="circlePath"
                    d={`
                M 150, 150
                m -120, 0
                a 120,120 0 0,1 240,0
                a 120,120 0 0,1 -240,0
              `}
              />
            </defs>
            <g>
              <text>
                <textPath xlinkHref="#circlePath">
                  {badgeItem.description}
                </textPath>
              </text>
            </g>
          </svg>
        </div>
      </div>
    </div>
  );

}

export default Badge;
