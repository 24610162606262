import {useEffect, useState} from 'react'
import {socialIcons} from "../../layout/SocialIcons";

const defaultSocialData = [
    {link: "https://www.linkedin.com/company/headstart-agency/", name: "linkedin"},
    {link: "https://www.instagram.com/headstartagency.eu/", name: "instagram"},
    {link: "https://www.youtube.com/@headstartagencyeu", name: "youtube"},
    {link: "https://www.tiktok.com/@head.start.agency", name: "tiktok"},
];

const GetSocialMediaData = () => {

    const [socialMedia, setSocialMedia] = useState([]);

    useEffect(() => {
          fetch('/api/socials')
              .then((response) => {
                  if (response.ok) {
                      return response.json();
                  }
                  return defaultSocialData;
              })
              .then((responseJson) => {
                  setSocialMedia(responseJson);
               })
              .catch(() => {
                  return defaultSocialData;
              });

    }, []);

    if(socialMedia.length === 0)
        return defaultSocialData;

    return socialMedia;
};

export const useSocialMediaData = () => {

    let social = GetSocialMediaData();

    return social.map(item => {
        const icon = socialIcons[item.name];
        if (!icon) {
            return null;
        }
        return {
            link: item.link,
            icon: icon
        };
    }).filter(Boolean);
};
