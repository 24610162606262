import React from "react";
import {Helmet} from "react-helmet";
import {Container} from "react-bootstrap";
import TitleSection from "../../components/heading/TitleSection";
import InfluencersPortfolio from "../../components/influencers/InfluencersPortfolio";
import {DATA_SOURCE, INFLUENCERS_TYPE} from "../../config";
import {useData} from "../../api/fetchData";

function Influencers(props) {
    const influencersData = useData(DATA_SOURCE.INFLUENCERS);
    const influencersFollowersData = useData(DATA_SOURCE.INFLUENCERS_FOLLOWERS);

    return (<React.Fragment>
            <Helmet>
                <title>Influencerzy - Head Start Agency</title>
            </Helmet>

            <div className="p-3"></div>
            <Container className="section-margin" id="influencers">
                <TitleSection description="influencers">
                    NASI INFLUENCERZY
                </TitleSection>
                <InfluencersPortfolio influencers={influencersData} influencersFollowers={influencersFollowersData}
                                      influencersType={INFLUENCERS_TYPE.PRIMARY} grabCursor loop autoHeight/>
                {influencersData?.collaborators?.length > 0 && <>
                    <div className="pt-40"></div>
                    <h4 className="pb-40 font-size-20-mobile">WSPÓŁPRACUJEMY TAKŻE Z</h4>

                    <InfluencersPortfolio influencers={influencersData}
                                          influencersFollowers={influencersFollowersData}
                                          influencersType={INFLUENCERS_TYPE.COLLABORATOR} grabCursor loop autoHeight
                                          isPrimary={false}
                                          col={4} colTablet={3}/>
                </>}
            </Container>

        </React.Fragment>);
}

export default Influencers;
