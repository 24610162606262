import React from "react";
import LazyImg from "../LazyImg";

const VideoWrapper = ({src, video, imageOnly, alt}) => {

    return (
        <>
            {!video && <LazyImg src={src} className="cover-bg-img" alt={alt}/>}
            {(video && !imageOnly) &&
                <video style={{width: "inherit"}} controls={true} preload="metadata" autoPlay muted playsInline loop={true} poster={src} >
                    <source src={video} type="video/mp4"/>
                </video>}
            {(video && imageOnly) && <LazyImg className="cover-bg-video" src={src} alt={alt}/>}
        </>
    );
};

export default VideoWrapper;
