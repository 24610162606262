import { useSelector } from 'react-redux';

const useHandleClick = () => {
    const scrollbar = useSelector(state => state.scrollbar);

    const handleClick = (targetId) => {
        const element = document.getElementById(targetId);
        if (window.location.pathname !== '/') {
            window.location.href = '/#' + targetId;
        } else if (element && scrollbar) {
            scrollbar.current.scrollIntoView(element);
        }
    };

    return handleClick;
}

export default useHandleClick;
