import {dsnCN} from "../../hooks/helper";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {socialIcons} from "../../layout/SocialIcons";
import MetaPost from "../header/MetaPost";
import "./styles.scss";
import {faComment, faThumbsUp, faUsers} from "@fortawesome/free-solid-svg-icons";

function CaseStudyInfo({item, className}) {
    const groupedMetrics = {};

    if(item.metrics)
        item.metrics.forEach(metric => {
            if (!groupedMetrics[metric.platform]) {
                groupedMetrics[metric.platform] = [];
            }
            groupedMetrics[metric.platform].push(metric);
        });

    return (
        <div className={dsnCN('case-study', className)}>
            <MetaPost category={item.category} separate=", "/>
            <h2>
                {item.company || item.title}
            </h2>
            {item.title && item.company && item.title !== item.company && (
                <h4 className="mt-1">{item.title}</h4>
            )}
            {item.subTitle && <p>{item.subTitle}</p>}
            <div className="mb-40"></div>


            {
                item.brief &&
                <div>
                    {item.brief.intro && <div className="pb-4">
                        <h5 className="text-uppercase">W skrócie:</h5>
                        <p dangerouslySetInnerHTML={{__html: item.brief.intro}}></p>
                    </div>}
                    {item.brief.goal  && <div className="pb-4">
                        <h5 className="text-uppercase">Założenia:</h5>
                        <p dangerouslySetInnerHTML={{__html: item.brief.goal}}></p>
                    </div>}
                </div>
            }

            {item.metrics && <h5 className="text-uppercase">Osiągnięte wyniki:</h5>          }
            {Object.keys(groupedMetrics).map(platform => (
                <div className="platform-metrics-box" key={platform}>
                    <div className="platform">
                <FontAwesomeIcon className="me-2" icon={socialIcons[platform]} /> {platform.charAt(0).toUpperCase() + platform.slice(1)}
                    </div>
                <ul>
            {groupedMetrics[platform].map((metric, index) => (
                <li key={index}>
            {metric.type && <div className="metric-type">{metric.type}</div>}

                    <div className="metric-info">
                        {metric.reach > 0 && (
                            <div className="metric-item">
                                <div className="metric-label">
                                    <FontAwesomeIcon className="me-2" icon={faUsers}/>
                                    Zasięg:
                                </div>
                                <div className="metric-score">{metric.reach.toLocaleString()}</div>
                            </div>
                        )}
                        {metric.likes > 0 && (
                            <div className="metric-item">
                                <div className="metric-label">
                                    <FontAwesomeIcon className="me-2" icon={faThumbsUp}/>
                                    Polubienia:
                                </div>
                                <div className="metric-score">{metric.likes.toLocaleString()}</div>
                            </div>
                        )}
                        {metric.comments > 0 && (
                            <div className="metric-item">
                                <div className="metric-label">
                                    <FontAwesomeIcon className="me-2" icon={faComment}/>
                                    Komentarze:
                                </div>
                                <div className="metric-score">{metric.comments.toLocaleString()}</div>
                            </div>
                        )}
                    </div>


                </li>
                ))}
                </ul>
                </div>
                ))}

            {item.campaignUrl && <div className="text-center mt-5">
                <a type="button" className="lh-base button bg-dark" href={item.campaignUrl} target="_blank" rel="noreferrer">
                    Zobacz oficjalną kampanię
                </a>
            </div>}
        </div>
    );
}
export default CaseStudyInfo;
