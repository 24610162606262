import {useEffect, useState} from "react";
import {getResourceUrl} from "../urlUtils";
import {DATA_DIR} from "../config";

const dataCache = {};
const promiseCache = {};

export const useData = (dataFile, maxItems: number | null = null) => {
    const [data, setData] = useState([]);

    const normalizePath = (path) => {
        return path.replace(/^\/+|\/+$/g, "");
    };

    useEffect(() => {

        const normalizedPath = normalizePath(dataFile);

        const fullPath = normalizedPath.startsWith(DATA_DIR)
            ? normalizedPath
            : `${DATA_DIR}${normalizedPath}`;

        const cacheKey = `${fullPath}_${maxItems}`;


        if (dataCache[cacheKey]) {
            setData(dataCache[cacheKey]);
            return;
        }

        if (promiseCache[cacheKey]) {
            promiseCache[cacheKey].then(fetchedData => setData(fetchedData));
            return;
        }

        promiseCache[cacheKey] = fetch(getResourceUrl(fullPath))
            .then(response => response.json())
            .then(fetchedData => {

                // If maxItems is specified and data is an array, slice the data
                if (typeof maxItems === 'number' && Array.isArray(fetchedData)) {
                    fetchedData = fetchedData.slice(0, maxItems);
                }

                dataCache[cacheKey] = fetchedData;
                delete promiseCache[cacheKey];
                setData(fetchedData);
            })
            .catch(error => {
                console.error("Error fetching data for", dataFile, error);
            });
    }, [dataFile, maxItems]);

    return data;
};
