import React, {useEffect, useRef, useState} from "react";
import {Helmet} from "react-helmet";
import {Container} from "react-bootstrap";
import TitleSection from "../../components/heading/TitleSection";
import DsnGrid from "../../components/DsnGrid";
import PortfolioItem from "../../components/portfolio/PortfolioItem";
import Isotope from "isotope-layout";
import "../../components/portfolio/style.scss";
import {useData} from "../../api/fetchData";
import {DATA_SOURCE} from "../../config";

function CaseStudies(props) {

    const iso = useRef();
    const [filter] = useState('*');
    const dataPortfolio = useData(DATA_SOURCE.CASE_STUDIES);
    const refIsotope = useRef();

    useEffect(() => {
        iso.current = new Isotope(refIsotope.current, {itemSelector: '.portfolio-item'});

        return () => {
            iso.current.destroy();
        }
    });

    useEffect(()=>{
        iso.current.arrange({filter : filter});
    },[filter])


    return (
        <React.Fragment>
            <Helmet>
                <title>Kampanie - Head Start Agency</title>
            </Helmet>

            <div className="p-3"></div>
            <Container className="section-margin" id="casestudies">
                <Container as={TitleSection} description="case studies">
                    WYBRANE KAMPANIE
                </Container>
                <section className='dsn-portfolio port-classic'>
                    <DsnGrid ref={refIsotope}  col={3} colTablet={2} colMobile={1}>
                        {dataPortfolio && dataPortfolio.map((item, index) =>
                            <PortfolioItem autoHeight key={index} portoDetails={item} textButton="Zobacz"/>
                        )}
                    </DsnGrid>
                </section>

            </Container>

        </React.Fragment>
    );
}

export default CaseStudies;
