export const DATA_DIR = "data/";

export const DATA_SOURCE = {
    INFLUENCERS: "influencers.json",
    INFLUENCERS_FOLLOWERS: "influencers.socials.json",
    BADGES: "badges.json",
    PARTNERS: "partners.json",
    CASE_STUDIES: "case_studies.json",
    ABOUT: "about.json"
};

export const INFLUENCERS_TYPE = {
    PRIMARY: "primary",
    COLLABORATOR: "collaborators"
}
